import { FormControl, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { KEY_CODES } from "../../../constants";
import { preventDefaultBehaviour } from "../../../services/commonMethods";
import { ValidationContext } from "../../../services/FormValidator";
import ValidationMessage from "../../../services/ValidationMessage";

export default function CustomInput(props) {
  let { formControlProps, inputProps } = props;
  const DEFAULT_DECIMAL_LIMIT = 2;
  //Default form control props.
  formControlProps = formControlProps || {
    fullWidth: true,
    margin: "normal",
    className: "add-screen-formcontrol mt-3",
  };

  const { type } = inputProps;
  const contextValid = useContext(ValidationContext);

  const onKeyPress = (ev) => {
    let moveForward = true;

    if (inputProps.disableSpaces && ev.code === KEY_CODES.SPACE_KEY) {
      //If space is not allowed, set moveForward to false.
      moveForward = false;
    }

    if (
      type === "number" &&
      (ev.code === KEY_CODES.DOT_KEY || ev.code === KEY_CODES.NUMPAD_DOT_KEY) &&
      !props.decimalValuesEnabled
    ) {
      //If input type is number & entered key is  .(period), then do not allow to change value
      moveForward = false;
    }

    if (
      type === "number" &&
      inputProps.disableNegativeValue &&
      ev.code === KEY_CODES.NEGATIVE_KEY
    ) {
      moveForward = false;
    }

    //For firefox (to not allow user enter any non-numeric key & allow '-' Minus).
    //equality operation prevents entering of space key as isNaN(spaceKey)=false.
    if (
      type === "number" &&
      ev.code !== KEY_CODES.NEGATIVE_KEY &&
      ev.code !== KEY_CODES.DOT_KEY &&
      ev.code !== KEY_CODES.NUMPAD_DOT_KEY &&
      isNaN(ev.key.trim() === "" ? "non-numeric" : ev.key)
    ) {
      moveForward = false;
    }

    return moveForward ? undefined : preventDefaultBehaviour(ev);
  };

  //Check if any rule is passed to the component
  const handleOnChange = (ev) => {
    const { onChange } = inputProps;
    const DECIMAL_POINT = ".";
    let min = inputProps.inputProps ? inputProps.inputProps.min : undefined;
    let max = inputProps.inputProps ? inputProps.inputProps.max : undefined;
    let { target } = ev;
    const { value } = target;
    if (min && value < min && value !== "") {
      return preventDefaultBehaviour(ev);
    }
    if (max && value > max && value !== "") {
      return preventDefaultBehaviour(ev);
    }

    //For decimal values.
    if (type === "number" && props.decimalValuesEnabled) {
      //Finds out the decimal value from number and check if the length of it is in limit.
      if (
        value.includes(DECIMAL_POINT) &&
        value.substr(value.indexOf(DECIMAL_POINT) + 1).length >
          (props.decimalLimit || DEFAULT_DECIMAL_LIMIT)
      ) {
        return preventDefaultBehaviour(ev);
      }
    }

    if (
      type === "text" &&
      value &&
      inputProps.regExp &&
      !inputProps.regExp.test(value)
    ) {
      return preventDefaultBehaviour(ev);
    }
    if (
      type === "number" &&
      inputProps.disableNegativeValue &&
      parseInt(value) < 0
    ) {
      return preventDefaultBehaviour(ev);
    }
    if (type === "text" && inputProps.uppercase) {
      target.value = target.value.toUpperCase();
    }
    onChange(ev);
  };

  return (
    <FormControl {...formControlProps}>
      <TextField
        {...inputProps}
        onKeyPress={onKeyPress}
        onChange={handleOnChange}
      >
        {props.children}
      </TextField>
      {contextValid &&
      contextValid.errors &&
      contextValid.errors[inputProps.name] ? (
        <ValidationMessage name={inputProps.name} />
      ) : (
        <span className="text-danger error-msg">{props.errorMessage}</span>
      )}
    </FormControl>
  );
}
