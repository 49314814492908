import React, { Component } from "react";
import CustomButton from "../pages/components/Button";
import validation from "./validation";

export const ValidationContext = React.createContext();

class FormValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      formSubmitted: false,
      liveChanges: this.props.liveChanges,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  componentDidMount() {
    if (!this.props.liveChanges) {
      return;
    }

    //Attach event listeners to each input element.
    Object.keys(this.props.rules).forEach((ele) => {
      //Checks for input element validation on blur
      document.getElementsByName(ele)[0]?.addEventListener("blur", () => {
        let nextErrors = JSON.parse(JSON.stringify(this.state.errors));
        let currentInputValidation = null;

        //If error occurred, append it.
        if (
          (currentInputValidation = validation(this.props.data, {
            [ele]: this.props.rules[ele],
          }))
        ) {
          nextErrors = { ...nextErrors, [ele]: currentInputValidation[ele] };
        } else {
          //If no error occurred, delete the existing error if any
          delete nextErrors[ele];
        }
        this.setState({
          errors: nextErrors,
        });
      });
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (state.formSubmitted) {
      return {
        errors: validation(props.data, props.rules),
      };
    }
  }
  get formValid() {
    return Object.keys(this.state.errors).length === 0;
  }

  handleClick = (btnType, submitFunc) => {
    if (btnType === "submit") {
      this.setState(
        {
          formSubmitted: true,
        },
        () => {
          if (this.formValid) {
            submitFunc();
          }
        }
      );
    } else {
      submitFunc();
    }
  };

  render() {
    const { btns, btnContainerClass } = this.props;
    return (
      <ValidationContext.Provider value={this.state}>
        <form onSubmit={this.handleFormSubmit} noValidate>
          <div>{this.props.children}</div>
          <div
            style={{ marginLeft: "1rem" }}
            className={`${btnContainerClass} btn-class`}
          >
            {btns.map((btn, index) => {
              const {
                type,
                btnProps: { method, ...restBtnprops },
              } = btn;
              return (
                <CustomButton
                  btnProps={{ ...restBtnprops }}
                  onClick={() => this.handleClick(type, method)}
                  type={type}
                  disabled={
                    (!this.formValid &&
                      this.state.formSubmitted &&
                      type === "submit") ||
                    !!restBtnprops.disabled
                  }
                  key={index}
                />
              );
            })}
          </div>
        </form>
      </ValidationContext.Provider>
    );
  }
}

export default FormValidator;
